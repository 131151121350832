import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

window.apiurl = "https://arivonline-api.bumitechresearch.com";
window.siteurl = "https://arivonline.bumitechresearch.com/#";
window.captcha_site_key = "6LfKQ9AUAAAAAO5azUFkGEKgYIqUaiZrdheby1vP";
window.user = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
